import axios from "axios";
import React, { useState,useEffect } from "react";
import { SERVER_URL } from "../../../config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
const Collborate = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(4);
  const [tableEntries, setTableEntries] = useState([]);
  const [currentStory, setCurrentStory] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/fetchusers`);
        setTableEntries(response.data); 
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  let currentEntities = tableEntries.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const handlePopup = (story) => {
    setCurrentStory(story); 
    setIsPopupOpen((prev) => !prev); 
  };
  return (
    <div>
      <div className="pt-20">
        <p className=" text-2xl font-bold text-black ">Collaborate with me</p>
        <div className="flex justify-between items-center gap-3 my-5">
          
        </div>
        <div className="bg-[#0A0F2185] rounded-2xl py-5 w-full mx-auto flex justify-between items-center ">
          <div className="w-[95%] mx-auto">
            <div className="font-san">
              <p className="text-end text-xs px-3 mb-2">
                Show no of entity
                <select
                  className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                  onChange={handleDropdownChange}
                  value={entitiesPerPage}
                >
                  <option className="text-black" value="4">
                    4
                  </option>
                  <option className="text-black" value="5">
                    5
                  </option>
                  <option className="text-black" value="10">
                    10
                  </option>
                </select>
              </p>
              <div className="pb-5  overflow-hidden">
                <div className="overflow-x-auto">
                  <table className="w-full text-center">
                    <thead>
                      <tr className="border-b border-white/40">
                        <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                          Name
                        </th>
                        <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                          Email
                        </th>
                        <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                          Phone Number
                        </th>
                        <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                          Details
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                    {currentEntities.map((entity, index) => (
                      <tr
                        key={index}
                        className="h-16 w-full border-b border-white/40 text-xs md:text-sm cursor-pointer rounded-xl"
                      >
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.firstName} {entity.lastName}
                        </td>
                        <td className=" px-5 lg:px-0 whitespace-nowrap">
                          {entity.email}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.phoneNumber}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          <button
                            onClick={() => handlePopup(entity.story)} 
                            className="bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] rounded-full shadow-md px-5 py-1 text-sm text-black"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
              
                   
                    {isPopupOpen && (
                      <div className="w-full fixed inset-0 flex justify-center items-center bg-black/50 z-50">
                        <div className="bg-[#EAEAEA] p-5 rounded-md">
                          <div className="flex justify-between items-center">
                            <h3 className="text-lg font-semibold text-black">Story</h3>
                            <button
                              className="text-black font-bold text-lg"
                              onClick={() => setIsPopupOpen(false)} // Close the popup
                            >
                              &times;
                            </button>
                          </div>
                          <div className="mt-4 text-black">{currentStory}</div>
                        </div>
                      </div>
                    )}
                  </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-2 flex justify-end text-xs px-10">
                <div className="flex gap-3">
                  <button
                    className={`bg-white/20  rounded-md px-2 py-1  `}
                    onClick={handlePrevClick}
                    disabled={currentPage === 1}
                  >
                  <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <button
                    className={` bg-white/20 rounded-md px-2 py-1  `}
                    onClick={handleNextClick}
                    disabled={currentEntities.length < entitiesPerPage}
                  >
                  <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Collborate;