import { useEffect } from 'react';
import AOS from 'aos';
const Section9 = () => {
  useEffect(() => {
    AOS.init({
        duration: 1000, 
        once: false,    
    });
}, []);
  return (
    <div className=" bg-[#FCF7F7] bg-cover h-full">
      <div className="w-[100%]  mx-auto py-10">
        <h1 className=" font-marcellus text-4xl lg:text-6xl font-medium text-center " data-aos="fade-in" data-aos-duration="5000">
          Join Our Whatsapp <span>Community</span>
        </h1>
        <div className=" w-[100%] flex md:flex-row flex-col items-center justify-between mt-10 gap-10">
          <div className=" md:w-[30%] flex md:flex-col flex-row gap-5 items-center">
            <div className="w-full md:flex justify-end ">
              <img data-aos="fade-right" src="/assets/Landing/user4.svg" className="md:w-4/12" />
            </div>
            <div className="w-full md:flex justify-center md:mt-10 md:pl-5">
              <img
                src="/assets/Landing/user2.svg"
                className="sm:w-10/12 md:w-6/12"
                data-aos="fade-right"
              />
            </div>
          </div>
          <div className=" md:w-[40%] mx-auto font-roboto ">
            <p className=" w-full text-center " data-aos="fade-up">
              It’s a community more than just updates built around Shared
              knowledge, Valuable insights, Info on upcoming sessions, and a
              chance to be part of our growing conversation.{" "}
            </p>

            <p className="w-full text-center mt-5 text-xl font-bold" data-aos="fade-up">
              Let’s make things happen together!
            </p>

            <div className=" flex justify-center mt-10 " data-aos="fade-up">
              <a href='https://chat.whatsapp.com/ELHF9TFRRVEL6Wep7i1ZBQ' target='_blank'><button className="uppercase px-10 font-semibold py-3 shadow-md shadow-black/25 bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] rounded-lg text-sm text-white text-center ">
                click here
              </button></a>
            </div>
          </div>

          <div className=" md:w-[30%] flex md:flex-col flex-row gap-5">
            <div className="w-full md:flex justify-start">
              <img data-aos="fade-left" src="/assets/Landing/user1.svg" className=" md:w-7/12 " />
            </div>
            <div className=" w-full md:flex justify-center  md:pr-5">
              <img
                src="/assets/Landing/user3.svg"
                className=" w-11/12 md:w-5/12"
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section9;
