import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../../config";

  const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [isvisble, setIsVisible] = useState(false);
  const navigate = useNavigate();

  const handleValidation = () => {
    if (!email) {
      toast.error("Email is required.");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error("Enter a valid email address.");
      return false;
    }

    if (!password) {
      toast.error("Password is required.");
      return false;
    } else if (password.length < 6) {
      toast.error("Password must be at least 6 characters.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (handleValidation()) {
      try {
        const response = await axios.post(`${SERVER_URL}/api/admin-login`, {
          email,
          password,
        });
  
        if (response.status === 200) {
          toast.success("Login successful!");
          console.log(email);
           localStorage.setItem("admin_email",email)
          
          window.location.href = "/dashboard";
        }
      } catch (error) {
        toast.error(
          "Login failed. Please try again."
        );
      }
    }
  };

  return (
    <div
      className="w-full bg-cover h-screen overflow-hidden overflow-y-auto font-poppins "
      style={{
        backgroundImage: "linear-gradient(to bottom, #FFBA6E, #FF6061)",
      }}
    >
      <div className="w-full  bg-black/50 h-screen overflow-hidden overflow-y-auto ">
        <div className="w-full  flex lg:flex-row flex-col ">
          <div className="relative lg:w-1/2 h-screen bg-cover bg-no-repeat">
            <div
              className="absolute inset-0 bg-black opacity-10 bg-cover"
              style={{ backgroundImage: "url('/assets/Admin/bglogin.jpeg')" }}
            ></div>

            <div
              className="w-full bg-cover bg-no-repeat "
              style={{
                backgroundImage: "url('/assets/Admin/bglogin2.svg')",
                opacity: 0.9,
              }}
            >
              <div className="w-full bg-black/40 h-screen flex flex-col lg:justify-end justify-center items-center lg:items-end">
                <div className="w-[80%] mx-auto text-white bg-[#D9D9D90F] rounded-md py-2">
                  <p className="uppercase text-3xl text-center">
                    Login as admin
                  </p>
                </div>

                <div className="w-full flex md:flex-row flex-col items-start mt-10">
                  <div className="md:w-[55%]">
                    <img
                      src="/assets/Admin/KPimg.svg"
                      className="w-full"
                      alt="KP Image"
                    />
                  </div>

                  <div className="md:w-[45%] md:mt-24 md:-translate-x-20 mt-10">
                    <p className="text-xs text-center text-white">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean lacinia tortor sit amet maximus tristique. Integer
                      leo sapien, v
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-1/2 py-10 lg:py-0 h-screen bg-cover flex justify-center items-center">
            <div className="lg:w-[80%] w-[95%] max-w-lg  mx-auto bg-white rounded-xl px-5 py-10 ">
              <p className="text-2xl text-center font-medium">Login</p>
              <div className=" w-[80%] mx-auto ">
                
                

                <div>
                  <div>
                    <label className=" text-xs text-[#666666] ">
                      Your email
                    </label>
                    <input
                      type="text"
                      className={`w-full focus:outline-none rounded-lg px-5 py-2 border ${
                        errors.email ? "border-red-500" : "border-[#66666659]"
                      }`}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <p className="text-xs text-red-500 mt-1">
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <div className="mt-5">
                    <div className="flex justify-between">
                      <label className=" text-xs text-[#666666] ">
                        Your password
                      </label>

                      {isvisble ? (
                        <div
                          role="button"
                          onClick={() => setIsVisible(!isvisble)}
                          className="flex items-center gap-1"
                        >
                          <i
                            class="fa-solid fa-eye-slash"
                            style={{ color: "#666666CC" }}
                          ></i>
                          <p className="text-xs text-[#666666CC]">Hide</p>
                        </div>
                      ) : (
                        <div
                          role="button"
                          onClick={() => setIsVisible(!isvisble)}
                          className="flex items-center gap-1"
                        >
                          <i
                            class="fa-solid fa-eye"
                            style={{ color: "#666666CC" }}
                          ></i>

                          <p className="text-xs text-[#666666CC]">Show</p>
                        </div>
                      )}
                    </div>
                    <input
                      type={isvisble ? "text" : "password"}
                      className={`w-full mt-1 focus:outline-none rounded-lg px-5 py-2 border ${
                        errors.password
                          ? "border-red-500"
                          : "border-[#66666659]"
                      }`}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {errors.password && (
                      <p className="text-xs text-red-500 mt-1">
                        {errors.password}
                      </p>
                    )}
                   
                  </div>
                  <div className="mt-10">
                    <button
                      onClick={handleSubmit}
                      className="font-semibold rounded-md text-white text-center py-2 w-full bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] "
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
