import React from 'react';
import { BrowserRouter, Routes, Route,Navigate, useNavigate } from 'react-router-dom';
import Landing from '../Components/Pages/Home/Landing';
import Header from '../Components/Layout/Header'
import Login from '../Components/Pages/Admin/Login';
import Dashboard from '../Components/Pages/Admin/Dashboard';



const Routers = () => {
  const admin_email = localStorage.getItem("admin_email") || null;
  console.log(admin_email);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />

        <Route path="/admin" element={<Login />} />
        
        <Route path="/dashboard" element=
           
        {
          admin_email ?
          <Dashboard />
          : 
          <Navigate to ="/admin" />
        } />






      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
