import React, { useState, useEffect } from "react";
import Emails from "./Emails";
import Collborate from "./Collaborate";
import Testimonials from "./Testimonials";
import { Navigate } from "react-router-dom";

export default function Dashboard() {
  const [isOpenNavbar, setOpenNavbar] = useState(false);

  const handleOpenNavbar = () => {
    setOpenNavbar(!isOpenNavbar);
  };

  const [activeTab, setActiveTab] = useState("file");
  const [active, setActive] = useState("div1");

  const handleTabClick = (tab) => {
    setActiveTab(tab === activeTab ? null : tab);
    if (tab === "file") {
      handleAnchorClick("div1");
    } else {
      switch (tab) {
        case "clock":
          handleAnchorClick("div2");
          break;
        case "bell":
          handleAnchorClick("div3");
          break;
        default:
          handleAnchorClick("div1");
      }
    }
  };

  const handleAnchorClick = (tab) => {
    setActive(tab);
  };

  const TabButton = ({ label, tab }) => {
    return (
      <button
        className={`flex justify-center w-[100%] mx-auto py-2.5  ${
          tab === activeTab ? "bg-black/35 font-bold" : ""
        }`}
        onClick={() => handleTabClick(tab)}
      >
        <span
          className={`${
            tab === activeTab ? "text-white font-bold" : " text-white/70 "
          }`}
        >
          {label}
        </span>
      </button>
    );
  };

  const Logout = ()=>{
     localStorage.removeItem("admin_email");
     window.location.reload();
  }

  return (
    <div
      className="font-poppins bg-cover min-h-screen bg-no-repeat text-white"
      style={{
        backgroundImage: "linear-gradient(to bottom, #FFBA6E, #FF6061)",
      }}
    >
      <div className=" md:flex">
        <div className="hidden relative lg:block w-[15%] h-screen ">
          <div className="">
            <img
              src="../assets/bg/bglogin.jpeg"
              className="h-screen opacity-20"
            />
          </div>
          <div className="w-full bg-black/30 absolute top-0 z-30 py-10 h-screen ">
            <div className=" flex flex-col justify-center items-center ">
              <div className=" w-14 h-14 rounded-lg bg-white "><img className="w-14 h-14" src="/assets/Admin/KPimg.svg"></img></div>
              <p className="font-semibold mt-3 ">Welcome Admin</p>
              <p className="text-xs">Kalpesh Patel</p>
            </div>
            <div className="w-full">
              <nav className="mt-10  ">
                <div className=" flex flex-col items-center gap-5 ">
                  <TabButton label="Collaborate with me" tab="file" />
                  <TabButton label="Testimonial  " tab="clock" />
                  <TabButton label="Emails" tab="bell" />
                  <button className="bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] rounded-full shadow-md  px-5 py-2 text-sm font-medium text-black " onClick={Logout}>Logout</button>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className="block lg:hidden p-5">
          <button onClick={handleOpenNavbar}>
            <svg
              class="w-6 h-6 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h14M1 6h14M1 11h7"
              />
            </svg>
          </button>
          {isOpenNavbar && (
            <nav className="w-[80%] md:w-[50%] z-10 absolute border rounded-3xl bg-black/85 text-white grid content-around ">
              <div>
                <div className="py-5">
                  <TabButton label="Collaborate with me" tab="file" />
                  <TabButton label="Testimonial  " tab="clock" />
                  <TabButton label="Emails" tab="bell" />
                  <div />
                </div>
              </div>
            </nav>
          )}
        </div>

        <div
          id="div1"
          style={{ display: active === "div1" ? "block" : "none" }}
          className="lg:w-[75%] w-[95%] flex justify-center items-center mx-auto"
        >
         <Collborate/>
        </div>

        <div
          id="div2"
          style={{ display: active === "div2" ? "block" : "none" }}
          className="lg:w-[80%] w-[95%] mx-auto"
        >
          <Testimonials />
        </div>

        <div
          id="div3"
          style={{ display: active === "div3" ? "block" : "none" }}
          className="lg:w-[75%] w-[95%] mx-auto"
        >
          <Emails />
        </div>
      </div>
    </div>
  );
}