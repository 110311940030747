import { useEffect,useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import AOS from 'aos';
const Section7 = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            once: false,    
        });
    }, []);
    const [videoplay2, setVideoplay2] = useState(false);

    const handleVideoplay2 = () => {
      setVideoplay2((prev) => !prev);
    };
    return (
        <div className='w-full bg-[#FCF7F7]  flex justify-center items-center font-roboto pt-8 pb-3' >
            <div className='w-[85%] mx-auto flex md:flex-row flex-col justify-center items-center gap-10 md:gap-0' >
                <div className='md:w-[65%]' >
                    <h1 data-aos="fade-right" className=' font-marcellus text-4xl lg:text-6xl font-medium ' >BLOG</h1>
                    <div className='mt-14' data-aos="fade-right" >
                        <p className='md:w-[80%] text-xl md:text-3xl font-bold' >My Journey Through MLM and Direct Selling: Empowerment, Integrity, and Success — Kalpesh Batook Patel</p>
                        <p className='mt-5 md:w-[60%]' >It’s been a wild ride full of lessons, growth, and resilience. If you’re in the trenches, chasing your dreams, this one’s for you. This isn’t just a story of business—it about resilience, grit, and personal growth.</p>
                    </div>
                    <div className=' flex justify-start mt-10 ' data-aos="fade-right" >
                        <a href='https://medium.com/@kalpeshpatel_10422/my-journey-in-mlm-lessons-learned-from-overcoming-challenges-kalpesh-patel-fd09865a57d8 ' target='_blank'><button className='uppercase px-10 font-semibold py-3 shadow-md shadow-black/25 bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] rounded-lg text-sm text-white text-center ' >read more</button></a>
                    </div>
                </div>
                <div className=' md:w-[35%] ' >
                    <div className='relative flex justify-center items-center ' data-aos="fade-up">
                        <a  onClick={handleVideoplay2} ><img src='/assets/Landing/KPimg2.svg' className=' scale-110 ' /></a>
                        <a onClick={handleVideoplay2} className='absolute mb-5 bg-black/40 rounded-full cursor-pointer w-10 h-10 flex justify-center items-center'>
                            <i class="fa-solid fa-play" style={{ color: '#FFFFFF' }}></i>
                        </a>
                        
                    </div>
                </div>

            </div>
            {videoplay2 && (
                <div className="z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen">
                  <div
                    className="md:w-[50%] bg-white shadow-lg rounded-2xl"
                    data-aos="fadeIn"
                  >
                   
                    <div className="flex justify-end p-3">
                      <FontAwesomeIcon
                        className="cursor-pointer text-3xl text-gray-700 hover:text-gray-900"
                        onClick={handleVideoplay2}
                        icon={faXmarkCircle}
                      />
                    </div>
        
                   
                    <iframe
                      width="100%"
                      height="500"
                      src="https://www.youtube.com/embed/gcHYPYnLhvU?autoplay=1" 
                      title="Video Player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: "15px", overflow: "hidden" }}
                    ></iframe>
                  </div>
                </div>
              )}
        </div>
    )
}

export default Section7