import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faYoutube, faInstagram, faDribbble, faLinkedin } from '@fortawesome/free-brands-svg-icons';
export default function Footer() {
  return (
    <div>
    <footer className="bg-black text-white py-10">
    <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 text-center md:text-left gap-8 px-4">
      <div>
        <h3 className="text-lg font-semibold mb-2"><FontAwesomeIcon icon={faPhone} className='text-[#F2574D] mr-2' /> CALL US</h3>
        <p className="text-sm text-gray-400">UK: (+44) 729 131 194</p>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2"><FontAwesomeIcon icon={faEnvelope} className='text-[#F2574D] mr-2' /> EMAIL US</h3>
        <p className="text-sm text-gray-400">contact@kalpeshpatel.com</p>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2"><FontAwesomeIcon icon={faLocationDot} className='text-[#F2574D] mr-2' />ADDRESS</h3>
        <p className="text-sm text-gray-400">
          P.O Box 16122 Collins Street West<br />
          LONDON 8007 UK
        </p>
      </div>
    </div>
    <div className="mt-10 text-center space-y-2">
      <h1 className='text-center'>Follow us on</h1>
      <div className="flex justify-center space-x-4">
        <a href="https://www.facebook.com/SuccessIsAChoice/" target='_blank' className="hover:text-red-500"><FontAwesomeIcon icon={faFacebook} /></a>
        <a href="https://x.com/kalpeshXinspire" target='_blank' className="hover:text-red-500"><FontAwesomeIcon icon={faTwitter} /></a>
        <a href="https://www.instagram.com/kalpesh_inspired_patel/" target='_blank' className="hover:text-red-500"><FontAwesomeIcon icon={faInstagram} /></a>
        <a href="https://www.youtube.com/@TheKalpeshPatel" target='_blank' className="hover:text-red-500"><FontAwesomeIcon icon={faYoutube} /></a>
        <a href="https://www.linkedin.com/feed/" target='_blank' className="hover:text-red-500"><FontAwesomeIcon icon={faLinkedin} /></a>
      </div>
      <p className='text-gray-500'>Kalpesh Patel &copy; 2020</p>
    </div>
  </footer>
    </div>
  )
}
