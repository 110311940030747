import React from 'react'
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Section3() {
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            once: false,    
        });
    }, []);
  return (
    <div className='overflow-hidden'>
    <div className='bg-cover bg-center' style={{ backgroundImage: 'url("/assets/Landing/aboutbg.svg")' }}>
        <div className='flex flex-col-reverse lg:flex-row w-[90%] mx-auto gap-10 min-h-screen '>
            <div className='w-[100%] lg:w-[40%] grid content-end '>
                <div data-aos="fade-up"><img src='/assets/Landing/KP3.svg' className='mx-auto '></img></div>
            </div>
            <div className='w-[100%] lg:w-[60%] grid content-center gap-10 text-center py-3'>
                <h1 className=' font-medium text-6xl font-marcellus' data-aos='fade-left' data-aos-duration='1000'>About Kalpesh</h1>
                <p className='font-bold text-xl font-roboto'>My Entrepreneurial journey started with a simple vision</p>
                <p className='font-medium leading-10 text-lg font-roboto'>To inspire and lead. From humble beginnings, I’ve grown to become a global influencer in business innovation. My focus has always been on leadership development, empowering others to overcome challenges and achieve extraordinary results.</p>
              
            </div>
        </div>
    </div>
</div>
  )
}
