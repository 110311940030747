import { React, useEffect, useState, useRef } from "react";
import Radio from "@mui/material/Radio";
import axios from "axios";
import toast from "react-hot-toast";
import AOS from "aos";
import { SERVER_URL } from "../../../config";
export default function Section5() {
  const [selectedValue, setSelectedValue] = useState("a"); 
  const ids = ["a", "b", "c", "d"];
  const intervalRef = useRef(null);

  const handleChanges = (event) => {
    clearInterval(intervalRef.current); 
    setSelectedValue(event.target.value); 
    startAutoLoop(); 
  };

  const startAutoLoop = () => {
    intervalRef.current = setInterval(() => {
      setSelectedValue((prev) => {
        const currentIndex = ids.indexOf(prev);
        const nextIndex = (currentIndex + 1) % ids.length;
        return ids[nextIndex];
      });
    }, 6000); 
  };

  useEffect(() => {
    startAutoLoop();

   
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);
 
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    additionalInfo: "",
    
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number";
    }
    if (!formData.additionalInfo.trim()) {
      newErrors.additionalInfo = "Additional information is required";
    }
   

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validate()) {
      try {
        const response = await axios.post(`${SERVER_URL}/api/collaborate`, formData);
        toast.success('Form submitted successfully');
        console.log(response);
  
        setTimeout(() => {
          setIspopupopen(false);
        }, 3000);
  
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          additionalInfo: '',
        });
      } catch (error) {
        if (error.response && error.response.status === 409) {
          const message = error.response.data.message;
          toast.error(message); // Show the specific error message
        } else {
          toast.error('Error submitting form');
          console.error('Error:', error);
        }
      }
    } else {
      toast.error('Please correct the errors in the form');
    }
  };



 

  const [isopenpopup, setIspopupopen] = useState(false);

  const handlepopup = () => {
    setIspopupopen(!isopenpopup);
  };

  return (
    <div className="overflow-hidden">
      <div className="min-h-screen grid content-center bg-gradient-to-b from-[#FFBA6E] to-[#FF6061] pb-5">
        <div>
          <div
            className="flex justify-center gap-5 text-3xl md:text-4xl lg:text-6xl 2xl:text-7xl  text-white font-medium font-marcellus pt-10 lg:pt-0"
            style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
          >
            <h1 data-aos="fade-right" data-aos-duration="1500">
              Collaborate{" "}
            </h1>
            <h1 data-aos="fade-left" data-aos-duration="1500">
              With Me
            </h1>
          </div>
          <div className="mt-10 ">
            {/* Div 1 */}
            {selectedValue === "a" && (
              <div
                className="flex flex-col lg:flex-row justify-around items-center w-[90%] mx-auto"
                id="1"
              >
                <div className="w-[100%] lg:w-[50%]">
                  <div className="bg-white/50 shadow-md text-center p-10 2xl:p-20 rounded-2xl">
                    <h1
                      className="font-bold text-3xl font-roboto"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      Speaking Engagements
                    </h1>
                    <p
                      className="text-xl mt-5 leading-8 font-roboto"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                    I love connecting with audiences to inspire and drive action. If you need a speaker who can motivate and engage, I’m here to make your event memorable. 
                    </p>
                  </div>
                </div>
                <div className="w-[100%] lg:w-[30%]">
                <div className="relative flex justify-center items-center mt-10 mb-10">
          
                <img
                  src="/assets/Landing/AllKP.svg" 
                  alt="First"
                  className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 rotate-6  absolute"
                />
          
                
                <img
                  src="/assets/Landing/AllKP.svg" 
                  alt="Second"
                  className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 -rotate-6  relative"
                  data-aos='fade-up' data-aos-duration='2000'
                />
              </div>
                </div>
              </div>
            )}

            {/* Div 2 */}
            {selectedValue === "b" && (
              <div
                className="flex flex-col lg:flex-row justify-around items-center w-[90%] mx-auto"
                id="2"
              >
                <div className="w-[100%] lg:w-[50%]">
                  <div className="bg-white/50 shadow-md text-center p-10 2xl:p-20 rounded-2xl">
                    <h1
                      className="font-bold text-3xl font-roboto"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                    Strategic Partnerships 
                                        </h1>
                    <p
                      className="text-xl mt-5 leading-8 font-roboto"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                    Strategic partnerships are essential for long-term success. My consulting services provide personalized advice to help you solve problems and reach your goals. 
                    </p>
                  </div>
                </div>
                <div className="w-[100%] lg:w-[30%]">
                <div className="relative flex justify-center items-center mt-10 mb-10">
          
                <img
                  src="/assets/Landing/AllKP.svg" 
                  alt="First"
                  className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 rotate-6  absolute"
                />
          
                
                <img
                  src="/assets/Landing/AllKP.svg" 
                  alt="Second"
                  className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 -rotate-6  absolute"
                 
                />
                <img
                src="/assets/Landing/Rectangle 2216.svg" 
                alt="Third"
                className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 -rotate-8  relative"
                data-aos='fade-up' data-aos-duration='2000'
              />
              </div>
                </div>
              </div>
            )}

            {/* Div 3 */}
            {selectedValue === "c" && (
              <div
                className="flex flex-col lg:flex-row justify-around items-center w-[90%] mx-auto"
                id="3"
              >
                <div className="w-[100%] lg:w-[50%]">
                  <div className="bg-white/50 shadow-md text-center p-10 2xl:p-20 rounded-2xl">
                    <h1
                      className="font-bold text-3xl font-roboto"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                    Consulting
                    </h1>
                    <p
                      className="text-xl mt-5 leading-8 font-roboto"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                    Every business faces unique challenges. My consulting services offer tailored guidance to help you overcome obstacles and achieve your goals.
                    </p>
                  </div>
                </div>
                <div className="w-[100%] lg:w-[30%]">
                <div className="relative flex justify-center items-center mt-10 mb-10">
          
                <img
                  src="/assets/Landing/AllKP.svg" 
                  alt="First"
                  className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 rotate-6  absolute"
                />
          
                
                <img
                  src="/assets/Landing/AllKP.svg" 
                  alt="Second"
                  className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 -rotate-6  absolute"
                 
                />
                <img
                src="/assets/Landing/Rectangle 2216.svg" 
                alt="Third"
                className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 -rotate-8  absolute"
              />
              <img
              src="/assets/Landing/Rectangle 2213.svg" 
              alt="Fourth"
              className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 -rotate-10  relative"
              data-aos='fade-up' data-aos-duration='2000'
            />
              </div>
                </div>
              </div>
            )}
            {/* Div 4 */}
            {selectedValue === "d" && (
              <div
                className="flex flex-col lg:flex-row justify-around items-center w-[90%] mx-auto"
                id="4"
              >
                <div className="w-[100%] lg:w-[50%]">
                  <div className="bg-white/50 shadow-md text-center p-10 2xl:p-20 rounded-2xl">
                    <h1
                      className="font-bold text-3xl font-roboto"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                    Joint Ventures 
                                        </h1>
                    <p
                      className="text-xl mt-5 leading-8 font-roboto"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                    I’m open to partnering on innovative projects. Whether it’s launching a new venture or exploring new markets, let’s combine our strengths and see what we can achieve. 
                    </p>
                  </div>
                </div>
                <div className="w-[100%] lg:w-[30%]">
                   <div className="relative flex justify-center items-center mt-10 mb-10">
          
                <img
                  src="/assets/Landing/AllKP.svg" 
                  alt="First"
                  className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 rotate-6  absolute"
                />
          
                
                <img
                  src="/assets/Landing/AllKP.svg" 
                  alt="Second"
                  className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 -rotate-6  absolute"
                 
                />
                <img
                src="/assets/Landing/Rectangle 2216.svg" 
                alt="Third"
                className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 -rotate-8  absolute"
              />
              <img
              src="/assets/Landing/Rectangle 2213.svg" 
              alt="Fourth"
              className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 -rotate-10  absolute"
            />
            <img
            src="/assets/Landing/Rectangle 2219.svg" 
            alt="Fifth"
            className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12   relative"
            data-aos='fade-up' data-aos-duration='2000'
          />
              </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-center">
            <Radio
              checked={selectedValue === "a"}
              onChange={handleChanges}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
              color="default"
              size="small"
            />
            <Radio
              checked={selectedValue === "b"}
              onChange={handleChanges}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              color="default"
              size="small"
            />
            <Radio
              checked={selectedValue === "c"}
              onChange={handleChanges}
              value="c"
              name="radio-buttons"
              inputProps={{ "aria-label": "C" }}
              color="default"
              size="small"
            />
            <Radio
              checked={selectedValue === "d"}
              onChange={handleChanges}
              value="d"
              name="radio-buttons"
              inputProps={{ "aria-label": "D" }}
              color="default"
              size="small"
            />
          </div>
          <div className="mt-5 flex justify-center">
            <button
              onClick={handlepopup}
              className="px-5 py-2 rounded-md bg-white font-medium shadow-md"
              data-aos="fade-up"
              data-aos-duration="2250"
            >
              LET’S WORK TOGETHER
            </button>
          </div>
        </div>
      </div>
      {isopenpopup && (
        <div className="w-full fixed inset-0 flex justify-center items-center bg-black/50 z-50">
          <div className=" bg-[#EAEAEA] p-10 ">
            <div>
              <button
                className="w-full text-right text-black rounded-md font-semibold "
                onClick={handlepopup}
              >
                x
              </button>
            </div>

            <form onSubmit={handleSubmit} className="mt-10">
              <div className="flex md:flex-row flex-col  gap-5">
                <div className="md:w-1/2 w-full">
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="placeholder:text-black placeholder:font-bold w-full border border-black bg-transparent rounded-md py-3 px-4"
                    placeholder="First Name"
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm">{errors.firstName}</p>
                  )}
                </div>
                <div className="md:w-1/2 w-full">
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="placeholder:text-black placeholder:font-bold w-full border border-black bg-transparent rounded-md py-3 px-4"
                    placeholder="Last Name"
                  />

                  {errors.lastName && (
                    <p className="text-red-500 text-sm">{errors.lastName}</p>
                  )}
                </div>
              </div>

              <div className="flex md:flex-row flex-col  gap-5 mt-5">
                <div className="md:w-1/2 w-full">
                  <p className="border border-black rounded-md px-4 flex justify-between">
                    {" "}
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="placeholder:text-black placeholder:font-bold w-full b bg-transparent rounded-md py-3 "
                      placeholder="Email"
                    />
                    <img src="/assets/Icons/mail.svg"></img>
                  </p>
                  {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                </div>
                <div className="md:w-1/2 w-full">
                  <p className="border border-black rounded-md px-4 flex justify-between">
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className="placeholder:text-black placeholder:font-bold w-full bg-transparent rounded-md py-3 "
                      placeholder="Phone Number"
                    />
                    <img src="/assets/Icons/phone.svg"></img>
                  </p>
                  {errors.phone && (
                    <p className="text-red-500 text-sm">{errors.phone}</p>
                  )}
                </div>
              </div>

              <div className="mt-5">
                <textarea
                  name="additionalInfo"
                  value={formData.additionalInfo}
                  onChange={handleChange}
                  className="w-full placeholder:text-black placeholder:font-bold border border-black bg-transparent rounded-md py-3 px-4"
                  placeholder="Tell us your Story"
                ></textarea>
                {errors.additionalInfo && (
                  <p className="text-red-500 text-sm">
                    {errors.additionalInfo}
                  </p>
                )}
              </div>

              <div className="flex justify-center mt-5">
                <button
                  type="submit"
                  className="px-14 font-semibold py-2.5 shadow-md shadow-black/25 bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] rounded-xl text-sm text-white text-center"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
