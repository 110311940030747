import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';export default function Section4() {
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            once: false,    
        });
    }, []);
  return (
    <div>
            <div className='relative min-h-screen flex items-center justify-center'>
                <div data-aos='fade-in' data-aos-duration='1500' style={{zIndex: 2}}>
                    <div className='w-[90%] flex justify-start lg:-translate-x-10 -translate-x-3'>
                        <FontAwesomeIcon icon={faQuoteLeft} className='text-[#F45833] text-5xl' />
                    </div>
                    <div className='text-center '>
                        <h1 className='font-roboto text-3xl  md:text-5xl mt-5'>ALWAYS V.O.T.E YOU</h1>
                        <h2 className='font-roboto text-xl md:text-3xl mt-5'>ALWAYS </h2>
                        <p className=' font-roboto text-xl md:text-3xl'>Value Opinions That Empower <br /> YOU</p>
                    </div>
                    <p className='mt-5 md:text-xl text-end font-medium w-[80%] mx-auto font-heebo'>-Kalpesh Patel </p>
                </div>
                <div className='absolute bg-[#E5E5E5] p-10 w-[90%] md:w-[70%] h-[60vh] grid content-around mx-auto shadow-md rounded-2xl' data-aos='fade-up' data-aos-duration='1000'  style={{zIndex: 1}}>
                </div>
                <div className='absolute z-0 top-[50%] -translate-y-[50%] h-[20vh]  w-[100%] bg-gradient-to-b from-[#FFBA6EE5] to-[#FF6061E5]'>
                </div>
            </div>
        </div>
  )
}
