import React from "react";
import Header from "../../Layout/Header";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";
import Footer from "../../Layout/Footer";

export default function Landing() {
  return (
    <div className="overflow-hidden">
      <Header />

      <Section1 id="home" />
      <Section6 />
      <Section2 />
      <div id="aboutus">
        <Section3 />
      </div>
      <Section4 />
      <div id="collaborate">
        <Section5 />
      </div>
     
      <div id="blog">
        <Section7 />
      </div>
      <div id="contact">
        <Section8 />
      </div>
      <Section9 />

      <Footer />
    </div>
  );
}
